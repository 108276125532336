
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






























::v-deep {
  .product-card {
    height: 100%;
  }
}
